<template>
  <div class="list">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="list-container clearfix">
      <div class="pull-left list-container-aside" v-loading="loading">
        <div class="everything-nav-box">
          <div class="nav-item-title">{{title}}</div>
          <div class="nav-item-box">
            <div v-if="boxLeftList.length > 0" class="animation-box" :style="`transform: translateY(${boxLeftItemIndex * 44 - 44}px);`"></div>
            <div v-for="(item, index) of boxLeftList" :key="index"
              class="left-item cursor" :class="{'left-item-on':boxLeftItemIndex == index + 1}"
              @click="boxLeftItem(index, item)">
              <span v-if="item.num > 0" class="unread-num">{{item.num}}</span>
              <span style="padding-left: 20px;">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pull-right list-container-main">
        <div class="list-a" v-loading="listLoading">
          <div v-if="noData" class="no-data">
            <img :src="img" alt="">
          </div>
          <ul>
            <li v-for="(item, index) of list" :key="index"  class="news-li-border news-li-pag">
              <div class="news-left" style="width: 550px;" @click="toDetail(item.id, index)">{{item.title}}</div>
              <div class="news-time">{{item.publishTime}}</div>
              <div v-if="type == 'xxtz'" class="pag-span" :class="{'on':item.state == 2}">
                <span v-if="item.state == 1">未读</span>
                <span v-if="item.state == 2">已读</span>
              </div>
              <div v-if="type == 'hytp'" class="pag-span" :class="{'on':item.state == 2}">
                <span v-if="item.state == 1">未投</span>
                <span v-if="item.state == 2">已投</span>
              </div>
              <div class="news-time">
                <span v-if="item.voteStatus == 1" style="color: #FF8321;">未开始</span>
                <span v-if="item.voteStatus == 2" style="color: #5286FF;">进行中</span>
                <span v-if="item.voteStatus == 3">已结束</span>
              </div>

            </li>
          </ul>
          <Pagination :total="total" :size="size" :page="current" @PaginationFun="PaginationFun"></Pagination>
        </div>
      </div>
    </div>
    <Member></Member>
  </div>
</template>

<script>
import { getListTreeById, noticeCount, noticeList, votePageList } from '@/api/web'
import Member from '@/components/member/Member'
import Pagination from '@/components/pagination/Pagination.vue'
export default {
  components: {
    Member,
    Pagination
  },
  name: 'ListMember',
  data () {
    return {
      img: require('../../../assets/images/nodata.png'),
      noData: false,
      loading: false,
      listLoading: false,
      title: '',
      breadcrumbList: [
        { name: '首页', path: '/' },
        { name: '', path: '' },
        { name: '', path: '' }
      ],
      boxLeftItemIndex: 1,
      boxLeftList: [],
      type: '',
      list: [],
      total: 0,
      current: 1,
      size: 10
    }
  },
  watch: {
    '$route.query.id': function (n, o) {
      this.initialData()
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    toDetail (id, index) {
      if (this.type === 'xxtz') {
        if (this.list[index].state === 1) {
          this.list[index].state = 2
          this.boxLeftList.forEach((val, ind) => {
            if (val.code === 'xxtz') {
              val.num = val.num - 1
            }
          })
        }
        const { href } = this.$router.resolve({
          path: '/msgdetail',
          query: { id: this.$route.query.id, articleId: id }
        })
        window.open(href, '_blank')
      }
      if (this.type === 'hytp') {
        const { href } = this.$router.resolve({
          path: '/votedetail',
          query: { id: this.$route.query.id, articleId: id }
        })
        window.open(href, '_blank')
      }
    },
    initialData () {
      this.loading = true
      getListTreeById({ id: this.$route.query.id }).then(res => {
        this.loading = false
        if (res.code === '000') {
          const data = res.result[0]
          this.breadcrumbList[1].name = data.name
          this.title = data.name
          this.boxLeftList = data.children
          this.boxLeftItemIndex = 1
          if (this.$route.query.cid) {
            data.children.forEach((val, ind) => {
              if (val.id === this.$route.query.cid) {
                this.breadcrumbList[2].name = val.name
                this.boxLeftItemIndex = ind + 1
                if (val.code === 'hytp') {
                  // 投票
                  this.type = 'hytp'
                  this.getvotePageList()
                } else {
                  // 消息
                  this.type = 'xxtz'
                  this.getNoticeList()
                }
              }
            })
          } else {
            this.breadcrumbList[2].name = data.children[0].name
            this.$router.push({ path: data.children[0].url, query: { id: this.$route.query.id, cid: data.children[0].id } })
            if (data.children[0].code === 'hytp') {
              // 投票
              this.type = 'hytp'
              this.getvotePageList()
            } else {
              // 消息
              this.type = 'xxtz'
              this.getNoticeList()
            }
          }
          this.getNumber()
        }
      })
    },
    // 获取唯独数量
    getNumber () {
      noticeCount({}).then(res => {
        if (res.code === '000') {
          this.boxLeftList.forEach((val, ind) => {
            if (val.code === 'xxtz') {
              val.num = res.result.noticeCount
            }
            if (val.code === 'hytp') {
              val.num = res.result.vuteCount
            }
          })
        }
      })
    },
    // 获取消息通知列表
    getNoticeList () {
      this.listLoading = true
      const data = {
        cmsType: this.$route.query.cid,
        pageSize: this.size,
        pageNo: this.current
      }
      noticeList(data).then(res => {
        this.listLoading = false
        if (res.code === '000') {
          this.list = res.result.records
          this.total = res.result.total
          this.current = res.result.current
          if (res.result.total === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
        }
      })
    },
    // 获取投票列表
    getvotePageList () {
      this.listLoading = true
      const data = {
        cmsType: this.$route.query.cid,
        pageSize: this.size,
        pageNo: this.current
      }
      votePageList(data).then(res => {
        this.listLoading = false
        if (res.code === '000') {
          this.list = res.result.records
          this.total = res.result.total
          this.current = res.result.current
          if (res.result.total === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
        }
      })
    },
    boxLeftItem (index, item) {
      if (this.boxLeftItemIndex !== index + 1) {
        this.boxLeftItemIndex = index + 1
        this.$router.push({ path: '', query: { id: this.$route.query.id, cid: item.id } })
        this.breadcrumbList[2].name = item.name
        this.type = item.code
        this.list = []
        this.total = 0
        this.current = 1
        if (item.code === 'hytp') {
          // 投票
          this.getvotePageList()
        } else {
          // 消息
          this.getNoticeList()
        }
      }
    },
    PaginationFun (e) {
      this.current = e
      if (this.type === 'hytp') {
        this.getvotePageList()
      }
      if (this.type === 'xxtz') {
        this.getNoticeList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list-container{
  padding-bottom: 15px;
  .list-container-main {
    width: 920px;
    // padding: 0 25px 25px 25px;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .list-container-aside {
    width: 250px;
    background: linear-gradient(135deg, #165CFF 0%, #6795FF 100%);
    border-radius: 4px;
    .everything-nav-box {
      .nav-item-title {
        line-height: 30px;
        margin: 15px 0;
        line-height: 40px;
        color: #fff;
        position: relative;
        font-size: 22px;
        padding: 0 30px;
      }
      .nav-item-title::before {
        content: '';
        position: absolute;
        display: block;
        top: 8px;
        left: 10px;
        width: 4px;
        height: 25px;
        background: #FF9852;
      }
      .nav-item-box {
        position: relative;
        min-height: 450px;
        z-index: 2;
        padding-bottom: 50px;
        .left-item {
          width: 210px;
          margin-bottom: 10px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          color: #ffffff;
          padding-left: 20px;
          transition: all .3s;
          position: relative;
        }
        .left-item-on {
          font-weight: bold;
          color: #5286FF;
        }
      }
      .animation-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 210px;
        background-color: #ffffff;
        height: 34px;
        border-radius: 0 20px 20px 0;
        transition: all .3s;
        z-index: -1;
      }
    }
  }
}
.unread-num{
  height: 18px;
  min-width: 18px;
  background-color: #FF9852;
  color: #ffffff;
  line-height: 18px;
  font-size: 12px;
  border-radius: 5px;
  transform: scale(0.8);
  padding: 0 3px;
  text-align: center;
  position: absolute;
  top: 7px;
  left: 15px;
}
@import "../../../assets/style/list.css";
.list-a{
  padding: 20px 25px 80px 25px;
  min-height: 520px;
  position: relative;
}
</style>
